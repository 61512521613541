// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import dropdowns from './dropdowns'
import chat from '@src/views/apps/chat/store/reducer'
import todo from '@src/views/apps/todo/store/reducer'
import users from '@src/views/apps/user/store/reducer'
import users2 from '@src/views/apps/user2/store/reducer'
import requests from '@src/views/apps/request/store/reducer'
import userEngagements from '@src/views/apps/engagements/store/reducer'
import email from '@src/views/apps/email/store/reducer'
import invoice from '@src/views/apps/invoice/store/reducer'
import calendar from '@src/views/apps/calendar/store/reducer'
import ecommerce from '@src/views/apps/ecommerce/store/reducer'
import dataTables from '@src/views/tables/data-tables/store/reducer'
import adminOrganizations from '@src/views/adminpanel/organizations/store/reducer'
import adminCoaches from '@src/views/adminpanel/coaches/store/reducer'
import adminEngagements from '@src/views/adminpanel/engagements/store/reducer'
import coachProfile from '@src/views/apps/coachProfile/store/reducer'
import profileStats from '@src/views/apps/home/store/reducer'
import trainings from '@src/views/apps/training/store/reducer'
import adminTrainings from '@src/views/adminpanel/trainings/store/reducer'
import mediauploads from '@src/views/apps/learningcenter/videos/store/reducer'
import documentuploads from '@src/views/apps/learningcenter/documents/store/reducer'
import materials from '@src/views/apps/learningcenter/material/store/reducer'
import organizationData from '@src/views/apps/organization/store/reducer'
import courses from '@src/views/apps/learningcenter/courses/store/reducer'
import mycourses from '@src/views/apps/learningcenter/myCourses/store/reducer'
import adminCourses from '@src/views/adminpanel/courses/store/reducer'
import homepageCoachee from '@src/views/apps/homepage/store/reducer'
import videoListGrid from '@src/views/apps/homepage/store/reducer/videogrid'
import sessions from '@src/views/apps/learningcenter/sessions/store/reducer'
import adminTimeRequest from '@src/views/adminpanel/timeRequests/store/reducer'
import videocalls from '@src/views/apps/learningcenter/videoCalls/store/reducer'
import reflections from '@src/views/apps/learningcenter/reflections/store/reducer'
import adminSessionEngagements from '@src/views/adminpanel/engagementSessions/store/reducer'
import coachSessionEngagements from '@src/views/apps/learningcenter/engagementSessions/store/reducer'

const rootReducer = combineReducers({
  auth,
  todo,
  chat,
  email,
  users,
  users2,
  requests,
  userEngagements,
  navbar,
  layout,
  invoice,
  calendar,
  ecommerce,
  dataTables,
  dropdowns,
  adminOrganizations,
  adminCoaches,
  adminEngagements,
  coachProfile,
  profileStats,
  trainings,
  adminTrainings,
  mediauploads,
  materials,
  organizationData,
  documentuploads,
  courses,
  mycourses,
  adminCourses,
  homepageCoachee,
  sessions,
  videoListGrid,
  adminTimeRequest,
  videocalls,
  reflections,
  adminSessionEngagements,
  coachSessionEngagements
})

export default rootReducer
