const initialState = {
  data: [],
  trainingData: [],
  total: 1,
  totalTrainings: 1,
  params: {},
  allData: []
}

const userEngagementsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_USER_ENGAGEMENTS':
      return {
        ...state,
        //allData: action.allData,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
      case 'GET_USER_TRAINING_ENGAGEMENTS':
        return {
          ...state,
          //allData: action.allData,
          trainingData: action.trainingData,
          totalTrainings: action.TrainingTotalPages,
          params: action.params
        }
    // case 'DELETE_INVOICE':
    //   return { ...state }
    default:
      return { ...state }
  }
}
export default userEngagementsReducer
