// ** Initial State
const initialState = {
  allData: [],
  data: [],
  trainingData: [],
  chapters: [],
  courseName: null,
  courseId: null,
  total: 1,
  params: {},
  courseData: [],
  courseTotal: 1,
  courseParams: [],
  selectedRequest: {},
  isLoadingEngagements: true,
  isLoadingTrainings: true,
  courseProgress: 0,
  selectedDocument: {},
  documentDetail: {},
  canTakeCourse: false,
  sessions: [],
  sessionsParams: [],
  totalSessions: 1,
  selectedSession: {},
  timeRequests: [],
  timeRequestsParams: {},
  totalTimeRequests: 1,
  documentList: [],
  documentTotal: 1,
  documentParams: {}
}

const requests = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_ENGAGEMENT_REQUESTS_TABLE_LOADING_STATUS':
      return { ...state, isLoadingEngagements: action.data }
    case 'SET_TRAINING_REQUESTS_TABLE_LOADING_STATUS':
      return { ...state, isLoadingTrainings: action.data }
    case 'REQUESTS_GET_ENGAGEMENT_REQUESTS':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
        selectedRequest: {},
        isLoadingEngagements: false
      }
    case 'REQUESTS_GET_ENGAGEMENT_TRAININGS':
      return {
        ...state,
        trainingData: action.data,
        trainingTotal: action.totalPages,
        params: action.params,
        selectedRequest: {},
        isLoadingTrainings: false
      }
    case 'REQUESTS_GET_ENGAGEMENT_REQUEST_DATA':
      return {
        ...state,
        selectedRequest: action.selectedRequest
      }
    case 'ADD_ENGAGEMENT':
      return { ...state }
    case 'EDIT_ENGAGEMENT':
      return { ...state }
    case 'GET_ALL_DATA':
      return { ...state, allData: action.data }
    case 'REQUESTS_CLEAR_ENGAGEMENTS':
      return {
        ...state,
        selectedRequest: {},
        courseProgress: 0,
        documentList: [],
        selectedDocument: {},
        documentDetail: {},
        canTakeCourse: false,
        chapters: [],
        courseName: null,
        courseId: null
      }
    case 'REQUEST_GET_CHAPTERS_SHORT':
      return {
        ...state,
        canTakeCourse: action.data.canTakeCourse,
        startCourse: action.data.startCourse,
        courseName: action.data.courseName,
        chapters: action.data.chapterList,
        courseId: action.data.courseId
      }
    case 'REQUEST_CLEAR_CHAPTERS_SHORT':
      return { ...state, chapters: [], courseName: null, courseId: null }
    case 'REQUEST_SET_COURSE':
      return { ...state, courseName: action.data.courseName, courseId: action.data.courseId }
    case 'REQUEST_GET_CURRICULUM':
      return { ...state, chapters: action.data.chapterList, courseName: action.data.courseName, courseProgress: action.data.progressCourse }
    case 'REQUEST_GET_DOCUMENT_ENGAGEMENTS':
      return {
        ...state,
        documentTotal: action.totalPages,
        documentParams: action.params,
        documentList: action.data.documentEngagements
      }
    case 'REQUEST_GET_DOCUMENT_DETAILS':
      return { ...state, selectedDocument: action.data.materialDetail ?? {} }
    case 'REQUEST_CLEAR_SELECTED_DOCUMENT':
      return { ...state, selectedDocument: {} }
    case 'REQUEST_CLEAR_DOCUMENT_ENGAGEMENTS':
      return { ...state, documentList: {} }
    case 'REQUEST_COURSE_PROGRESS_GET_DATA':
      return {//aqui
        ...state,
        courseData: action.data,
        courseTotal: action.totalPages,
        courseParams: action.params,
        isLoadingCourse: false
      }
    case 'REQUEST_COURSE_PROGRESS_TABLE_LOADING':
      return { ...state, isLoadingCourse: action.data }
    case 'REQUEST_GET_SESSION_ENGAGEMENTS':
      return {
        ...state,
        totalSessions: action.totalPages,
        sessionsParams: action.params,
        sessions: action.data.sessionsEngagements.sessionList
      }
    case 'REQUEST_CLEAR_SESSION_ENGAGEMENTS':
      return { ...state, sessions: {} }
    case 'CLEAR_GET_SESSION_DETAILS':
      return { ...state, selectedSession: null }
    case 'REQUEST_GET_SESSION_DETAILS':
      return { ...state, selectedSession: action.data.sessionDetails ?? null }
    case 'REQUEST_GET_SESSION_TIME_REQUEST':
      return {
        ...state,
        totalTimeRequests: action.totalPages,
        timeRequestsParams: action.timeRequestsParams,
        timeRequests: action.data.sessionTimeRequestList
      }
    default:
      return { ...state }
  }
}
export default requests
