const DefaultDropdownOptions = {
  pronounOptions: [
    //{ value: '', label: 'Select User Pronouns...', number: 0 },
    { value: "DPR0001", label: "He / Him / His", number: 0 },
    { value: "DPR0002", label: "She / Her / Hers", number: 1 },
    { value: "DPR0003", label: "They / Their / Theirs", number: 2 }
  ],
  experienceLevelOptions: [
    //{ value: '', label: 'Select Experience Level...', number: 0 },
    { value: "CEL0001", label: "Junior Associate", number: 0 },
    { value: "CEL0002", label: "Mid-Level Associate", number: 1 },
    { value: "CEL0003", label: "Senior Associate", number: 2 },
    { value: "CEL0004", label: "Partner / Executive", number: 3 },
    { value: "CEL0005", label: "Lateral", number: 4 },
    { value: "CEL0006", label: "Professional Staff", number: 5 }
  ],
  userRoleOptions: [
    //{ value: '', label: 'Select User Role...' },
    { value: "R00001", label: "Administrator", appliesTo: "lawfirmonly" },
    { value: "R00002", label: "Coachee", appliesTo: "lawfirmonly" },
    { value: "R00003", label: "Coach", appliesTo: "lawfirmonly" }
  ],
  userLocationOptions: [
    //{ value: '', label: 'Select State...' },
    { value: "L00001", label: "New York" },
    { value: "L00002", label: "California" },
    { value: "L00003", label: "Illinois" },
    { value: "L00004", label: "Texas" }
  ],
  languageOptions: [
    //{ value: '', label: 'Select Language...' },
    { value: "ULG0001", label: "English" },
    { value: "ULG0002", label: "French" },
    { value: "ULG0003", label: "German" },
    { value: "ULG0004", label: "Italian" },
    { value: "ULG0005", label: "Mandarin" },
    { value: "ULG0006", label: "Japanese" },
    { value: "ULG0007", label: "Spanish" }
  ],
  modalityOptions: [
    //{ value: '', label: 'Select Modality...' },
    { value: "CM00001", label: "In person" },
    { value: "CM00002", label: "Virtual" },
    { value: "CM00003", label: "Willing to Travel" }
  ],
  instructionTypeOptions: [
    //{ value: '', label: 'Select Instruction Type...' },
    { value: "CI00001", label: "1 to 1" },
    { value: "CI00002", label: "Small Group (2 - 10)" },
    { value: "CI00003", label: "Large Group (11 +)" },
    { value: "CI00004", label: "Workshops / Presentations" },
    { value: "CI00005", label: "Offsites / Retreats" }
  ],
  instructionTypeTrainingOptions: [
    { value: "CI00002", label: "Small Group (2 - 10)" },
    { value: "CI00003", label: "Large Group (11 +)" },
    { value: "CI00004", label: "Workshops / Presentations" },
    { value: "CI00005", label: "Offsites / Retreats" },
    { value: "CI00006", label: "Keynote" },
    { value: "CI00007", label: "Course" }
  ],
  coachingSpecialtyOptions: [
    {
      value: "CS00001",
      label: "Business Development"
    },
    {
      value: "CS00023",
      label: "Career Coaching"
    },
    {
      value: "CS00002",
      label: "Coaching and Mentoring"
    },
    {
      value: "CS00003",
      label: "Communication"
    },
    {
      value: "CS00004",
      label: "Conflict Resolution"
    },
    {
      value: "CS00005",
      label: "Diversity, Equity and Inclusion"
    },
    {
      value: "CS00006",
      label: "Emotional Intelligence"
    },
    {
      value: "CS00007",
      label: "Executive Presence"
    },
    {
      value: "CS00008",
      label: "Imposter Syndrome"
    },
    {
      value: "CS00009",
      label: "Leadership"
    },
    {
      value: "CS00024",
      label: "Legal Writing"
    },
    {
      value: "CS00010",
      label: "LinkedIn / Social Media"
    },
    {
      value: "CS00011",
      label: "Making Partner"
    },
    {
      value: "CS00012",
      label: "Management"
    },
    {
      value: "CS00025",
      label: "Meditation"
    },
    {
      value: "CS00013",
      label: "Mindfulness"
    },
    {
      value: "CS00014",
      label: "Mindset"
    },
    {
      value: "CS00015",
      label: "Practice Management"
    },
    {
      value: "CS00016",
      label: "Presentation Skills"
    },
    {
      value: "CS00026",
      label: "Productivity"
    },
    {
      value: "CS00027",
      label: "Public Speaking"
    },
    {
      value: "CS00017",
      label: "Resilience"
    },
    {
      value: "CS00018",
      label: "Self-Confidence"
    },
    {
      value: "CS00028",
      label: "Succession Planning"
    },
    {
      value: "CS00019",
      label: "Time Management"
    },
    {
      value: "CS00029",
      label: "Transitions"
    },
    {
      value: "CS00020",
      label: "Wellness and Well-being"
    },
    {
      value: "CS00021",
      label: "Womens Empowerment"
    },
    {
      value: "CS00022",
      label: "Other"
    }
  ],
  yearsOfCoachingOptions: [
    //{ value: '', label: 'Select Years of Coaching Experience...' },
    { value: "YOC0001", label: "0-5 yrs" },
    { value: "YOC0002", label: "5 - 10 yrs" },
    { value: "YOC0003", label: "10 - 15 yrs" },
    { value: "YOC0004", label: "15 - 20 yrs" },
    { value: "YOC0005", label: "20+ yrs" }
  ],
  engagementStatusOptions: [
    //{ value: '', label: 'Select Engagement Status', number: 0 },
    { value: "pending", label: "Pending", number: 1 },
    { value: "active", label: "Active", number: 2 },
    { value: "completed", label: "Completed", number: 3 },
    { value: "notengaged", label: "Not Engaged", number: 3 }
  ],
  degreeOptions: [
    { value: "DT00001", label: "BA" },
    { value: "DT00002", label: "BS" },
    { value: "DT00003", label: "MA" },
    { value: "DT00004", label: "MS" },
    { value: "DT00005", label: "JD" },
    { value: "DT00006", label: "Phd" },
    { value: "DT00007", label: "MBA" },
    { value: "DT00008", label: "DESS" },
    { value: "DT00009", label: "LLB" },
    { value: "DT00010", label: "LLM" }
  ],
  coachProfileStatusOptions: [
    { value: "pending", label: "Pending", number: 1 },
    { value: "active", label: "Active", number: 2 },
    { value: "inactive", label: "Inactive", number: 3 }
  ],
  subjectMattersOptions: [
    {
      value: "CS00001",
      label: "Business Development"
    },
    {
      value: "CS00023",
      label: "Career Coaching"
    },
    {
      value: "CS00002",
      label: "Coaching and Mentoring"
    },
    {
      value: "CS00003",
      label: "Communication"
    },
    {
      value: "CS00004",
      label: "Conflict Resolution"
    },
    {
      value: "CS00005",
      label: "Diversity, Equity and Inclusion"
    },
    {
      value: "CS00006",
      label: "Emotional Intelligence"
    },
    {
      value: "CS00007",
      label: "Executive Presence"
    },
    {
      value: "CS00008",
      label: "Imposter Syndrome"
    },
    {
      value: "CS00009",
      label: "Leadership"
    },
    {
      value: "CS00024",
      label: "Legal Writing"
    },
    {
      value: "CS00010",
      label: "LinkedIn / Social Media"
    },
    {
      value: "CS00011",
      label: "Making Partner"
    },
    {
      value: "CS00012",
      label: "Management"
    },
    {
      value: "CS00025",
      label: "Meditation"
    },
    {
      value: "CS00013",
      label: "Mindfulness"
    },
    {
      value: "CS00014",
      label: "Mindset"
    },
    {
      value: "CS00015",
      label: "Practice Management"
    },
    {
      value: "CS00016",
      label: "Presentation Skills"
    },
    {
      value: "CS00026",
      label: "Productivity"
    },
    {
      value: "CS00027",
      label: "Public Speaking"
    },
    {
      value: "CS00017",
      label: "Resilience"
    },
    {
      value: "CS00018",
      label: "Self-Confidence"
    },
    {
      value: "CS00028",
      label: "Succession Planning"
    },
    {
      value: "CS00019",
      label: "Time Management"
    },
    {
      value: "CS00029",
      label: "Transitions"
    },
    {
      value: "CS00020",
      label: "Wellness and Well-being"
    },
    {
      value: "CS00021",
      label: "Womens Empowerment"
    },
    {
      value: "CS00022",
      label: "Other"
    },
    {
      value: "CS00030",
      label: "Feedback"
  },
  {
    value: "CS00031",
    label: "Team Building"
  },
  {
    value: "CS00032",
    label: "Remote / Hybrid Work"
  }
  ],
  cleOptions: [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
    { value: "Maybe", label: "Maybe" }
  ],
  trainingLevelsOptions: [
    { value: "Beginner", label: "Beginner" },
    { value: "Intermediate", label: "Intermediate" },
    { value: "Advanced ", label: "Advanced " }
  ],
  attendeesOptions: [
    { value: "1 - 10", label: "1 - 10" },
    { value: "11 - 25", label: "11 - 25" },
    { value: "25 - 50", label: "25 - 50" },
    { value: "50 - 100", label: "50 - 100" },
    { value: "+100", label: "+100" }
  ],
  publicVideoOptions: [
    { value: true, label: "Yes" },
    { value: false, label: "No" }
  ],
  tagVideoOptions: [
    { value: 'fashion', label: 'Fashion', number: 1 },
    { value: 'gaming', label: 'Gaming', number: 2 },
    { value: 'video', label: 'Video', number: 3 },
    { value: 'quote', label: 'Quote', number: 4 },
    { value: 'food', label: 'Food', number: 5 }
  ],
  mediaTypeOptions: [
    { value: 'document', label: 'Document' },
    { value: 'image', label: 'Image' },
    { value: 'spreadsheet', label: 'Spreadsheet' },
    { value: 'video', label: 'Video' },
    { value: 'slide', label: 'Slide' }
  ],
  mediaUploadsStatusOptions: [
    { value: 'ingesting', label: 'Ingesting' },
    { value: 'processing', label: 'Processing' },
    { value: 'packaging', label: 'Packaging' },
    { value: 'ready', label: 'Ready' },
    { value: 'published', label: 'Published' },
    { value: 'failed', label: 'Failed' }
  ],
  mediaTypeDocumentsOptions: [
    { value: 'document', label: 'Document' },
    { value: 'image', label: 'Image' },
    { value: 'spreadsheet', label: 'Spreadsheet' },
    { value: 'slide', label: 'Slide' }
  ],
  courseStatusOptions: [
    { value: 'published', label: 'Published' },
    { value: 'draft', label: 'Draft' }
  ],
  mediaTypeLessonOptions: [
    { value: 'document', label: 'Document' },
    { value: 'text', label: 'Text' },
    { value: 'video', label: 'Video' },
    { value: 'survey', label: 'Survey' }
  ],
  editEngagementStatusOptions: [
    { value: "pending", label: "Pending", number: 1 },
    { value: "active", label: "Active", number: 2 },
    { value: "completed", label: "Completed", number: 3 }
  ],
  countryOptions: [
    { value: 233, label: "United States"},
    { value: 39, label: "Canada"}
  ],
  visibilityAdminOptions: [{ value: "lawfirm", label: "Only my Law Firm" }],
  visibilityCoachOptions: [
    { value: "public", label: "Everyone" },
    { value: "private", label: "Only Law Firms" }
  ],
  visibilityDuecourseOptions: [
    { value: "public", label: "Everyone" },
    { value: "private", label: "Only Law Firms" }
  ],
  audienceOptions: [
    { value: "AL0001", label: "Junior" },
    { value: "AL0002", label: "Mid-Level" },
    { value: "AL0003", label: "Senior" },
    { value: "AL0004", label: "Partner" }
  ],
  sessionStatusOptions: [
    { value: "notscheduled", label: "Not Scheduled" },
    { value: "scheduled", label: "Scheduled" },
    { value: "completed", label: "Completed" },
    { value: "noshow", label: "No Show" },
    { value: "canceled", label: "Canceled" }
  ],
  sessionDurationOptions: [
    { value: 30, label: "30 minutes" },
    { value: 45, label: "45 minutes" },
    { value: 60, label: "60 minutes" }
  ],
  timeRequestStatusOptions: [
    { value: false, label: "Requested"},
    { value: true, label: "Approved"},
    { value: "", label: "All"}
  ]
}

export default DefaultDropdownOptions
